<template>
  <div class="page-holder">
    <VueAuthHeader class="header"></VueAuthHeader>
    <div class="main">
      <img src="@/assets/images/background/auth-background.jpg" alt="pm-aktif-bg" class="main-bg" />
    </div>
    <VueAuthLandingFooter class="footer"></VueAuthLandingFooter>
  </div>
</template>

<script>
import VueAuthHeader from '@/components/brand/VueAuthHeader/VueAuthHeader.vue';
import VueAuthLandingFooter from '@/components/brand/VueAuthLandingFooter/VueAuthLandingFooter.vue';
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import RoutesSecure from '@/router/routes/RoutesSecure.js';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';
import { Account } from '@/services/Api/index';
import StorageHelper from '@/utils/storageHelper';
import { clearAppData } from '@/mixins/storageUtils.js';

export default {
  name: 'PmtepLogin',
  components: { VueAuthHeader, VueAuthLandingFooter },

  created() {
    clearAppData('login');
    const userToken = this.$route.params.token;
    if (userToken) {
      this.$router.push({ name: RoutesAthentication.PmtepLogin.name });

      Account.loginPmtep(userToken).then(res => {
        const { Data } = res ? res.data : { Data: null };
        this.$store.dispatch('auth/setAuthData', Data).then(() => {
          if (Data) {
            // // Fetch the storage field which number user sent
            // new StorageHelper({
            //   id: process.env[storageFieldName],
            // }).set(this.rememberMe ? number : null);

            const { token, refreshToken, user, pos } = Data;

            if (token) {
              new StorageHelper({ id: process.env.VUE_APP_STORAGE_TOKEN_KEY }).set(token);
            }
            if (refreshToken) {
              new StorageHelper({ id: process.env.VUE_APP_STORAGE_REFRESH_TOKEN_KEY }).set(
                refreshToken.token,
              );
            }
            if (user) {
              new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY }).set(user);
            }

            if (pos) {
              const posArr = Array.isArray(pos) ? pos : [pos];

              if (posArr.length > 1) {
                this.$store.dispatch('auth/setPosList', posArr).then(() => {
                  this.$router.push(
                    `${RoutesRoot.Authentication.path}/${RoutesAthentication.Login.path}/${RoutesLogin.SelectPos.path}`,
                  );
                });
              } else {
                new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_POS_KEY }).set(
                  posArr[0],
                );
                window.location = `${RoutesRoot.Secure.path}/${RoutesSecure.Feed.path}`;
              }
            }
          }
        });
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.page-holder {
  min-width: $body-width;

  /deep/ .header {
    padding: palette-space-level('30') palette-space-level('30') palette-space-level('80')
      palette-space-level('20');
    .select-login-type-text {
      display: none;
    }
  }
  .main {
    position: relative;
    width: 100%;
    flex: 1;
    &-bg {
      width: 100%;
    }
  }
  .footer {
    padding: palette-space-level('20') palette-space-level('30');
  }
}
</style>
